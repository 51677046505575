import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function CertificateDown() {

	const today = (new Date()).getFullYear() + '-' + (((new Date()).getMonth() + 1) < 10 ? '0' + ((new Date()).getMonth() + 1).toString() :  (new Date()).getMonth() + 1  ) + '-' + ((new Date()).getDate() < 10 ? '0' + (new Date()).getDate() : (new Date()).getDate());
	const {id} = useParams();

	const [ matricula, setMatricula ] = useState("");
	const [ matricula_civil, setMatriculaCivil ] = useState("");
	const [ cedido, setCedido ] = useState("");
	const [ numCertificado, setNumCertificado ] = useState("");
	const [ fechaEfecto, setFechaEfecto ] = useState("");

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
		fetchCertificate();
	},[]);


	const fetchCertificate = async () => {
		await axios.get(`https://api-dt.seguronline.es/certificate/find/${id}`,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

			if(data[0].baja){
				navigate("/");
			}

			setMatricula(data[0].matricula);
			setNumCertificado(data[0].numCertificado);
			setCedido(data[0].cedido);
			setMatriculaCivil(data[0].matricula_civil);
			setFechaEfecto(data[0].fecha_efecto);

		});
	};

	const updateCertificate = async (e) => {
		e.preventDefault();

		const raw = {
			'numCertificado': numCertificado,
			'cedido': cedido,
			'matricula_civil': matricula_civil
		};

		await axios.patch(`https://api-dt.seguronline.es/certificate/cert/${id}`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
				console.log(data);
				alert("Certificado actualizado correctamente");
				navigate(`/`);

		}).catch(({response:{data}})=>{
			alert("Error al actualizar el certificado. Datos incorrectos");
		});
	};

	const handleBajas  = async (e) => {
		navigate(`/certificate/down/${id}`);
	};

	return (
		<Container>
			<br />
			<h2>Editar un Certificado</h2>
			<br />
			<br />
			<br />
			<Form onSubmit={updateCertificate}>
				<Row>
					<Col>
						<Form.Group controlId="matricula">
							<Form.Label>Matricula:</Form.Label>
							<Form.Control disabled={true} type="text" value={matricula}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="numCertificado">
							<Form.Label>Num. certificado:</Form.Label>
							<Form.Control type="text" value={numCertificado} onChange={(event) => {
								setNumCertificado(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dateDown">
							<Form.Label>Fecha de efecto:</Form.Label>
							<Form.Control type="date" value={fechaEfecto} disabled={true}/>
						</Form.Group>
					</Col>
				</Row>

				<br/>

				{cookies.user && cookies.user.id === 1 ?

					<Row>
						<Col>
							<Form.Group controlId="matricula_civil">
								<Form.Label>Matricula civil:</Form.Label>
								<Form.Control type="text" value={matricula_civil} onChange={(event) => {
									setMatriculaCivil(event.target.value);
								}}/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="cedido">
								<Form.Label>Cedido:</Form.Label>
								<Form.Select value={cedido} onChange={(event) => {
									setCedido(event.target.value);
								}}>
									<option value="0">No</option>
									<option value="1">Sí</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col>
						</Col>
					</Row>
					: '' }

				<br/>
				<Button variant="primary" className="mt-2" size="md" block="block" type="submit">
					Guardar
				</Button>
				<Button style={{float: "right"}} variant="danger" className="mt-2" size="md" block="block"
						onClick={handleBajas}>
					Ir a bajas
				</Button>
			</Form>
		</Container>
	);
}