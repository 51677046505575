import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link,useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ReactPaginate from "react-paginate";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
export default function CertificateIndexManage() {

	const [ certificates, setCertificates ] = useState(null);
	const [ totalItems, setTotalItems ] = useState(0);
	const itemsPerPage = 30;
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token','user']);

	const [stateValue, setStateValue] = useState("");
	const [dgtValue, setDgtValue] = useState("");
	const [cedidosValue, setCedidosValue] = useState("");
	const [fechaInicioValue, setFechaInicioValue] = useState("");
	const [fechaFinValue, setFechaFinValue] = useState("");


	const pageCount = Math.ceil(totalItems / itemsPerPage);

	useEffect(()=>{
		if( cookies.user && cookies.user.id === 1){
			if(!cookies.token){
				navigate("/login");
			}else{
				fetchCertificates(0);
			}

		}else{
			navigate("/");
		}
	},[]);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % totalItems;
		if( fechaInicioValue || fechaFinValue || stateValue || dgtValue || cedidosValue ) {
			fetchCertificates(newOffset,fechaInicioValue,fechaFinValue,stateValue,dgtValue,cedidosValue);
		}else{
			fetchCertificates(newOffset);
		}

	};

	const handleState = (event) => {
		event.preventDefault();
		let state =  event.target.value;

		setStateValue(state);
		fetchCertificates(0,fechaInicioValue,fechaFinValue,state,dgtValue,cedidosValue);

	};

	const handleFechaInicio = (event) => {
		event.preventDefault();
		let fechaInicio =  event.target.value;

		setFechaInicioValue(fechaInicio);
		fetchCertificates(0,fechaInicio,fechaFinValue,stateValue,dgtValue,cedidosValue);

	};

	const handleFechaFin = (event) => {
		event.preventDefault();
		let fechaFin =  event.target.value;

		setFechaFinValue(fechaFin);
		fetchCertificates(0,fechaInicioValue,fechaFin,stateValue,dgtValue,cedidosValue);

	};

	const handleDgt = (event) => {
		event.preventDefault();
		let dgt =  event.target.value;

		setDgtValue(dgt);
		fetchCertificates(0,fechaInicioValue,fechaFinValue,stateValue,dgt,cedidosValue);

	};

	const handleCedidos = (event) => {
		event.preventDefault();
		let cedidos =  event.target.value;

		setCedidosValue(cedidos);
		fetchCertificates(0,fechaInicioValue,fechaFinValue,stateValue,dgtValue,cedidos);

	};

	const handleExcel = (event) => {
		event.preventDefault();

		fetchCertificatesExcel(fechaInicioValue,fechaFinValue,stateValue,dgtValue,cedidosValue);

	};

	const fetchCertificatesExcel = async (fechaInicio,fechaFin,state,dgt,cedidos) => {
		await axios.post("https://api-dt.seguronline.es/certificate/excel" , {
			"fecha_inicio": fechaInicio,
			"fecha_fin": fechaFin,
			"state": state,
			"dgt": dgt,
			"cedido": cedidos,
			"search": '',
		},{
			responseType: 'blob', // important
			headers: {
				Authorization: `${cookies.token}`
			}
		}).then(({data}) => {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			let name = 'certificados';
			link.href = url;
			link.setAttribute('download', name+'.xlsx');
			document.body.appendChild(link);
			link.click();
		}).catch(({response:{data}})=>{
			alert("Error en la generación del Excel");
		});
	};

	const fetchCertificates = async (offset,fechaInicio,fechaFin,state,dgt,cedidos) => {
		if(fechaInicio || fechaFin || state || dgt  || cedidos ) {
			await axios.post("https://api-dt.seguronline.es/certificate/filter/" + offset, {
				"fecha_inicio": fechaInicio,
				"fecha_fin": fechaFin,
				"state": state,
				"dgt": dgt,
				"cedido": cedidos,
				"search": '',
			},{
				headers: {
					Authorization: `${cookies.token}`
				}
			}).then(({data}) => {
				setCertificates(data.certificate);
				setTotalItems(data.totalItems);
			});
		}else{
			await axios.get("https://api-dt.seguronline.es/certificate/paginate/" + offset, {
				headers: {Authorization: `${cookies.token}`}
			}).then(({data}) => {
				setCertificates(data.certificate);
				setTotalItems(data.totalItems);
			});
		}

	};


	return (
		<Container>
			<br/>
			<h1>Lista de Certificados</h1>

			<Container>
				<Row>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Estado:</Form.Label>
							<Form.Select onChange={handleState} >
								<option value="">Todos...</option>
								<option value="1">Dados de alta</option>
								<option value="2">Dados de baja</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Centro de trabajo:</Form.Label>
							<Form.Select onChange={handleDgt} >
								<option value="">Todos...</option>
								<option value="DGT">DGT</option>
								<option value="ATGC">ATGC</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Cedidos:</Form.Label>
							<Form.Select onChange={handleCedidos} >
								<option value="">Todos...</option>
								<option value="1">SI</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="fechaInicio">
							<Form.Label>Fecha de inicio:</Form.Label>
							<Form.Control type="date" value={fechaInicioValue} onChange={handleFechaInicio} />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="fechaFin">
							<Form.Label>Fecha de fin:</Form.Label>
							<Form.Control type="date" value={fechaFinValue} onChange={handleFechaFin} />
						</Form.Group>
					</Col>
					<Col style={{paddingTop:'8px'}}>
						<br/>
						<Button variant="primary" onClick={handleExcel}>Descargar Excel</Button>
					</Col>
					<Col style={{paddingTop:'17px'}}>
						<div style={{marginTop: "6px"}}>Total <br />certificados: {totalItems}</div>
					</Col>
				</Row>
			</Container>
			<Container className="text-center">

			</Container>
			<br />
			<Table striped hover responsive
				   variant="light">
				<thead>
				<tr>
					<th>Colectivo</th>
					<th>Marca</th>
					<th>Modelo</th>
					<th>Version</th>
					<th>Matrícula</th>
					<th>Prima/Extorno</th>
					<th>Estado póliza</th>
					<th>Cedido</th>
					<th>Fecha efecto</th>
					<th>Fecha baja</th>
				</tr>
				</thead>
				<tbody>
				{certificates ? certificates.map((certificate, index) => (


					certificate.baja ?
						<tr key={index}>
							<td>{certificate.colectivo}</td>
							<td>{certificate.marca}</td>
							<td>{certificate.modelo}</td>
							<td>{certificate.version}</td>
							<td>{certificate.matricula}</td>
							<td>{certificate.extorno > 0 ? '-' + certificate.extorno : certificate.importe}€</td>
							<td>{certificate.baja ? 'Baja' : 'Alta'}</td>
							<td>{certificate.cedido ? 'Sí' : ''}</td>
							<td>{certificate.fecha_efecto}</td>
							<td>{certificate.fecha_baja}</td>
						</tr>
						:
						<tr key={index}>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.colectivo}</Link>
								: certificate.colectivo}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.marca}</Link>
								: certificate.marca}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.modelo}</Link>
								: certificate.modelo}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.version}</Link>
								: certificate.version}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.matricula}</Link>
								: certificate.matricula}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.extorno > 0 ? '-' + certificate.extorno : certificate.importe}€</Link>
								: <>{certificate.extorno > 0 ? '-' + certificate.extorno : certificate.importe}€</>}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.baja ? 'Baja' : 'Alta'}</Link>
								: <>{certificate.baja ? 'Baja' : 'Alta'}</>}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.cedido ? 'Sí' : ''}</Link>
								: <>{certificate.cedido ? 'Sí' : ''}</>}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.fecha_efecto}</Link>
								: certificate.fecha_efecto}</td>
							<td>{cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
																				  to={"/certificate/" + certificate.id}>{certificate.fecha_baja}</Link>
								: certificate.fecha_baja}</td>
						</tr>
				)) : <tr>
					<td colSpan={12}>Cargando</td>
				</tr>}
				</tbody>
			</Table>
			<br/>
			<Container>
				<ReactPaginate
					breakLabel="..."
					nextLabel="siguiente >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={10}
					pageCount={pageCount}
					previousLabel="< anterior"
					marginPagesDisplayed={2}
					pageClassName="page-item text-primary-emphasis"
					pageLinkClassName="page-link text-primary-emphasis"
					previousClassName="page-item text-primary-emphasis"
					previousLinkClassName="page-link text-primary-emphasis"
					nextClassName="page-item text-primary-emphasis"
					nextLinkClassName="page-link text-primary-emphasis"
					breakClassName="page-item text-primary-emphasis"
					breakLinkClassName="page-link text-primary-emphasis"
					containerClassName="pagination text-primary-emphasis"
					activeClassName="active text-primary-emphasis"
					renderOnZeroPageCount={null}
				/>
			</Container>
		</Container>


	);
}